import React, {useState, useEffect} from 'react'
import {Cookies, getCookieConsentValue} from 'react-cookie-consent'
import { StickyFooter } from 'components/shared'
import { Avatar, Button } from 'components/ui'
import { HiOutlineBadgeCheck } from 'react-icons/hi'

const CookiesBanner = () => {

    
    const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
    const defaultCookie = getCookieConsentValue('cookieConsent') || false
    const [hasCookieConsent, setHasCookieConsent] = useState(defaultCookie)

	
  const loadGoogleAnalytics = () => {
    setHasCookieConsent(true)

    const script = document.createElement('script');
    script.src= `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
    script.async = true;
    document.head.appendChild(script)

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', `${googleAnalyticsId}`, {
        page_path: window.location.pathname,
    });
  }

  const onAccept = () => {
    Cookies.set('cookieConsent', true)
    loadGoogleAnalytics()
    setHasCookieConsent(true)
    return
  }

  const onDecline = () => {
    Cookies.set('cookieConsent', false)
    setHasCookieConsent(true)
    return
  }

  useEffect (() => {
    if(getCookieConsentValue() === true){
        loadGoogleAnalytics();
    }
  // eslint-disable-next-line
  }, [])

	const cookiesFooter = (
		<StickyFooter className="flex items-center justify-center py-4 z-50 mx-4"  stickyClass="">
                
        <div className="container">
          <div className={`flex flex-wrap items-center justify-between rounded-lg border bg-white p-4`}>
            
            
            <div className="w-full md:w-7/12 lg:w-2/3 flex items-center">
              
              <Avatar shape="circle" size='lg' className="bg-indigo-50 text-indigo-500 mr-4" icon={<HiOutlineBadgeCheck size={30}/>} />

              <div className="mb-6 md:mb-0">
                
                <h3 className="text-lg font-bold text-black">
                  We use cookies
                </h3>
                
                <p className="text-sm font-medium text-body-color">
                  Please accept our cookies so we can provide the best experience.
                </p>
              
              </div>
            </div>
            
            <div className="w-full md:w-5/12 lg:w-1/3">
              <div className="flex items-center space-x-3 md:justify-end">
                
                <Button onClick={() => onDecline()}>Decline</Button>
                <Button variant='solid' onClick={() => onAccept()}>Accept</Button>
                
              </div>
            </div>

          </div>
        </div>
      </StickyFooter>
	)

    return (
        !hasCookieConsent && cookiesFooter
    )
}

export default CookiesBanner
